import React from "react";
import styled from "@emotion/styled";
// components
import { FormLayout } from "../components/layout/formLayout";
// utils
import { Colors } from "../components/utils/colors";

const selectList = [
  { text: "ゴルフ関連製品" },
  { text: "カメラ関連製品" },
  { text: "医療関連製品" },
  { text: "ソフトウェア" },
  { text: "ハードウェア" },
];
const Form04Page = () => {
  return (
    <FormLayout
      flow01="true"
      flow02="false"
      flow03="false"
      progress01="true"
      progress02="true"
      progress03="true"
      progress04="false"
      progress05="false"
      visibleBack="true"
      visibleNext="true"
      hrefBack="/form03"
      hrefNext="/form05/"
      textBack="前の質問に戻る"
      textNext="次の質問に進む"
    >
      <SForm className="formwrap">
        <h3>関心のある商品を選択してください（複数選択可）</h3>
        <div>
          {selectList.map(({ text }) => {
            return (
              <SLabel key={text}>
                <input type="checkbox" />
                <button tabIndex="-1">
                  <i className="material-icons-outlined">done</i>
                </button>
                {text}
              </SLabel>
            );
          })}
        </div>
      </SForm>
    </FormLayout>
  );
};

export default Form04Page;

const SForm = styled.div`
  h3 {
    margin-top: 4rem;
    color: ${Colors.dark};
    opacity: 0.7;
  }
  div {
    width: 220px;
    label {
      &:first-of-type {
        margin-top: 3rem;
      }
    }
  }
`;

const SLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 8px;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  transition: border 0.2s;
  cursor: pointer;
  user-select: none;
  button {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.gray};
    margin-right: 0.5rem;
    border-radius: 8px;
    transition: all 0.2s;
    border: none;
    border: 1px solid ${Colors.gray};
    &:focus {
      outline: none;
      border-color: ${Colors.primary};
    }
    i {
      transition: all 0.2s;
      color: ${Colors.gray};
      transform: scaleX(0);
    }
  }
  input[type="checkbox"] {
    /* display: none; */
    opacity: 0;
    transform: scale(1.7);
    display: inline-flex;
    z-index: 1;
    margin-right: -1.2rem;
    &:checked {
      + button {
        background-color: ${Colors.primary};
        border-color: ${Colors.primary};
        i {
          color: ${Colors.light};
          transform: scaleX(1);
        }
      }
    }
    &:focus {
      + button {
        border-color: ${Colors.primary};
      }
    }
  }
  &:focus {
    outline: none;
    border: 1px solid ${Colors.primary};
  }
`;
